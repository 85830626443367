import React from "react";
import { TWUniqueness } from "../../assets/images";
import Button from "../../shared/Button";
import { Link } from "react-router-dom";

const Uniqueness = () => {
  return (
    <div className="flex max-[850px]:flex-col max-[850px]:flex-wrap pt-12 px-12 pb-6 max-[850px]:px-6 gap-8">
      <div className="w-3/5 max-[850px]:w-full text-white">
        <p className="font-clashDisplay text-[36px] max-md:text-[18px] font-medium mb-6">
          Uniqueness we bring to the table?
        </p>
        <p className="text-[18px] text-porcelain max-md:text-[14px] mb-6">
          Combining technological prowess with creative ingenuity, our team
          delivers solutions that seamlessly blend innovation and practicality,
          ensuring your digital aspirations are not just met, but exceeded.
        </p>
        <Link className="max-[880px]:hidden" to={"/works"}>
          <Button className="!bg-white !text-black !mt-6 !mb-12">
            Our Work
          </Button>
        </Link>
        <div className="mt-8 flex gap-8">
          <div className="shadow-lg p-3 flex items-center gap-2 rounded-md bg-[#181818] text-white text-center h-fit">
            <p className="text-[32px] max-md:text-[16px]">97%</p>
            <p className="text-[12px] max-md:text-[10px] text-right">
              Happy customers with successful projects.
            </p>
          </div>
          <div className="shadow-lg p-3 flex items-center gap-2 rounded-md bg-[#181818] text-white text-center h-fit">
            <p className="text-[32px] max-md:text-[16px]">50%+</p>
            <p className="text-[12px] max-md:text-[10px] text-right">
              Projects successfully completed.
            </p>
          </div>
        </div>
      </div>
      <div className="max-[850px]:p-0 mb-6 w-2/5 max-[850px]:w-full max-[850px]:h-[400px]">
        <div className="relative w-full h-full rounded-lg">
          <img
            className="w-full h-full object-cover rounded-lg"
            src={TWUniqueness}
            alt="What we bring"
          />
          <div className="max-[850px]:hidden shadow-lg p-3 absolute right-0 -bottom-12 bg-[#181818] text-white w-[150px] text-center h-fit">
            <p className="text-[32px]">75%</p>
            <p className="text-[12px]">
              Sure Success rate when you work with us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uniqueness;
