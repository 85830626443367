import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="my-4 w-full h-[5px] flex items-center bg-alto">
      <div className={`h-[5px] bg-elm ${progress}`}></div>
    </div>
  );
};

export default ProgressBar;
