import React from "react";

const Banner = () => {
  return (
    <div className="animate__animated animate__backInUp bg-black mt-16 px-24 max-[850px]:px-6 py-24 max-[850px]:py-12">
      <div className="flex justify-center pb-12 max-md:pb-0">
        <div className="flex flex-col items-center gap-4">
          <p className="px-16 max-md:px-8 max-md:py-3 py-5 bg-mineShaft rounded-lg text-[32px] max-md:text-[16px]">
            FAQ
          </p>
          <p className="text-[48px] max-md:text-[20px] text-center font-medium">
            Frequently Asked Questions
          </p>
          <div className="w-4/5 max-md:w-full">
            <p className="text-[24px] max-md:text-[14px] text-center opacity-80">
              Welcome to our FAQ page, your go-to resource for quick answers and
              assistance. We're here to simplify your experience and provide the
              information you need.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
