import React from "react";
import { servicesData } from "../../utils/data";
import { TWIconYellowPointedArrowRight } from "../../assets/svgs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div
      className={` bg-white flex flex-col items-center gap-6 px-24 py-12 max-[850px]:px-6`}
    >
      <div className="w-3/5">
        <p className="font-clashDisplay animate__zoomIn text-[28px] max-[700px]:text-[16px] text-center font-medium text-black">
          We offer services that connects you to your customers
        </p>
      </div>
      <div className="">
        <div className="servicesCard gap-8 ">
          {servicesData?.map((service, idx) => (
            <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5 }}
              key={idx}
              className="bg-black rounded-lg px-10 py-6 flex flex-col justify-between gap-3 "
            >
              <div className="flex justify-between items-center">
                {service.leftIcon}
                {service.rightIcon}
              </div>
              <div className="flex items-center flex-col mb-4">
                <p className="w-3/4 text-[20px] mt-3 mb-6 font-medium text-center">
                  {service.title}
                </p>
                <p className="text-silver text-[14px] text-center">
                  {service.body}
                </p>
              </div>
              <div>
                <Link to="/works" className="flex items-center gap-2">
                  <p className="text-[14px] text-gold">Explore</p>
                  <TWIconYellowPointedArrowRight className="w-6 h-6" />
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
