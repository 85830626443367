import React, { useEffect, useRef, useState } from "react";
import Button from "../../shared/Button";
import {
  TWIconArrowLeft,
  TWIconArrowRight,
  TWIconGreyArrowRight,
  TWIconGreyArrowLeft,
  TWIconHeroPerson,
} from "../../assets/svgs";
import { Link, NavLink } from "react-router-dom";
import { homeProjects } from "../../utils/data";
import ProgressBar from "../../shared/ProgressBar";
import { motion } from "framer-motion";
import { TWBusinessPartners } from "../../assets/images";

const Hero = () => {
  const [projectIdx, setProjectIdx] = useState(0);

  const timeoutRef = useRef(null);

  const delay = 4000;

  const handlePrevClick = () => {
    if (projectIdx === 0) {
      return;
    }
    setProjectIdx((prev) => prev - 1);
  };

  const handleNextClick = () => {
    if (projectIdx === homeProjects.length - 1) {
      return;
    }
    setProjectIdx((prev) => prev + 1);
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setProjectIdx((prevIndex) =>
          prevIndex === homeProjects.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [projectIdx]);

  return (
    <div className="hero mt-28 bg-black flex max-[880px]:flex-col gap-4 mb-10">
      <div className="w-1/2 flex max-[880px]:w-full flex-col gap-8 mb-8 mt-8">
        <div className="pl-12 max-[880px]:px-6 ">
          <p className="heroHeader text-[3rem] max-md:text-[2rem] max-[450px]:text-[1.5rem] font-extrabold">
            Forging Dreams into <br /> Digital Realities
          </p>
          {/* <p className="text-[3rem] max-md:text-[2em] max-[450px]:text-[1.5em] font-bold mb-6">
            Digital Realities
          </p> */}
          <div className="flex items-center gap-4 mt-8">
            <div>
              <TWIconHeroPerson className="w-12 h-12" />
            </div>
            <div className="">
              <p className="text-[16px] max-md:text-[12px] w-4/5 max-[880px]:w-full">
                We Transform Ideas into Innovative Tech Solutions, Empowering
                Africa's Future.
              </p>
            </div>
          </div>
        </div>
        <div className="max-[880px]:hidden px-12 max-[880px]:px-6 flex gap-4 max-[880px]:gap-0 items-center">
          <Link className="max-[880px]:w-full" to={"/contact"}>
            <Button className="max-[880px]:!w-full" green>
              Contact Us
            </Button>
          </Link>
          <Link className="max-[880px]:w-full" to={"/works"}>
            <Button className="max-[880px]:!w-full">Our Work</Button>
          </Link>
        </div>
        <div className="max-[880px]:hidden overflow-hidden max-w-full mt-0 mx-auto">
          <div
            className="whitespace-nowrap"
            style={{
              transform: `translate3d(${-projectIdx * 100}%, 0, 0)`,
              transition: "ease 1000ms",
            }}
          >
            {homeProjects.map((project, idx) => (
              <div key={idx} className="inline-block w-full whitespace-normal">
                <div className="pl-12 w-full max-[880px]:px-0 flex mt-10">
                  <div className="latestProject border w-4/5 border-elm bg-black py-3 min-[1000px]:w-[800px] max-[500px]:h-[340px] max-[880px]:px-2 px-8 ">
                    <div className="flex gap-3 my-2 items-center">
                      <p className="text-[16px] whitespace-nowrap">
                        Our Latest Project
                      </p>
                      <ProgressBar progress={project.width} />
                    </div>
                    <div className="flex items-start my-2 gap-6">
                      <p className="text-[13px] whitespace-nowrap">
                        {project.title}:
                      </p>
                      <p className="text-[13px]">{project.desc}</p>
                    </div>
                    <div className="flex items-center my-2 gap-6">
                      <p className="w-[80px] text-[13px]">Website:</p>
                      <NavLink
                        className="text-[13px] text-gold"
                        to={project.href}
                      >
                        {project.href}
                      </NavLink>
                    </div>
                  </div>
                  <div className="bg-black min-h-full flex justify-around flex-col py-1 px-1">
                    {projectIdx === 0 ? (
                      <TWIconGreyArrowLeft
                        className="w-8 h-8"
                        onClick={handlePrevClick}
                      />
                    ) : (
                      <motion.div
                        whileHover={{ scale: 0.7 }}
                        transition={{ duration: 0.5 }}
                        onClick={handlePrevClick}
                        className="cursor-pointer"
                      >
                        <TWIconArrowLeft className="w-8 h-8" />
                      </motion.div>
                    )}
                    {projectIdx === homeProjects.length - 1 ? (
                      <TWIconGreyArrowRight
                        className="w-8 h-8"
                        onClick={handleNextClick}
                      />
                    ) : (
                      <motion.div
                        whileHover={{ scale: 0.7 }}
                        transition={{ duration: 0.5 }}
                        onClick={handleNextClick}
                        className="cursor-pointer"
                      >
                        <TWIconArrowRight className="w-8 h-8" />
                      </motion.div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-1/2 max-[880px]:w-full max-[880px]:px-6 relative">
        <img
          className="w-full h-full object-cover rounded-2xl"
          src={TWBusinessPartners}
          alt="Forging dreams"
        />
        <div className="flex max-[880px]:hidden w-2/5 justify-center absolute -bottom-5 right-0">
          <Button className="!bg-white !text-black !px-6">
            20+ Successful Products
          </Button>
        </div>
      </div>
      <div className="min-[880px]:hidden flex flex-col mb-6">
        <div className="flex gap-0 items-center fixed bottom-0 w-full z-40">
          <Link className="w-full" to={"/contact"}>
            <Button className="!w-full" green>
              Contact Us
            </Button>
          </Link>
          <Link className="w-full" to={"/works"}>
            <Button className="!w-full">Our Work</Button>
          </Link>
        </div>
        <div className="mt-10 overflow-hidden max-w-full mx-auto">
          <div
            className="whitespace-nowrap"
            style={{
              transform: `translate3d(${-projectIdx * 100}%, 0, 0)`,
              transition: "ease 1000ms",
            }}
          >
            {homeProjects.map((project, idx) => (
              <div key={idx} className="inline-block w-full whitespace-normal">
                <div className="latestProject border border-elm bg-black py-3 px-6">
                  <div className="flex gap-3 my-2 items-center">
                    <p className="text-[14px] whitespace-nowrap">
                      Our Latest Projects
                    </p>
                    <ProgressBar progress={project.width} />
                  </div>
                  <div className="flex items-start my-2 gap-6">
                    <p className="min-w-[80px] text-[12px] whitespace-nowrap">
                      {project.title}:
                    </p>
                    <p className="text-[8px]">{project.desc}</p>
                  </div>
                  <div className="flex items-center my-2 gap-6">
                    <p className="text-[10px] w-[80px]">Website:</p>
                    <NavLink className="text-[8px] text-gold" to={project.href}>
                      {project.href}
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
