import React, { useEffect } from "react";
import Banner from "./Banner";
import Video from "./Video";
import Team from "./Team";
import MissionVision from "./MissionVision";
import Layout from "../../layout/weblayout";
import "animate.css";

const AboutContents = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout inverted>
      <Banner />
      <Video />
      <Team />
      <MissionVision />
    </Layout>
  );
};

export default AboutContents;
