import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <Link to={"/"} className="pb-1 border-b-2 border-eden">
        <p>Go back home</p>
      </Link>
    </div>
  );
};

export default NotFound;
