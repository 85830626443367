import React, { useEffect, useState } from "react";
import Message from "./Message";
import Call from "./Call";

const ContactUs = ({ isVisible, elemRef }) => {
  const getRoute = localStorage.getItem("route");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getRoute === "0"
      ? setActiveTab(0)
      : getRoute === "1"
      ? setActiveTab(1)
      : setActiveTab(0);
  }, [getRoute]);

  const contactTabs = [
    {
      title: "Send us a Message",
      component: <Message />,
    },
    {
      title: "Call us now",
      component: <Call />,
    },
  ];

  return (
    <div
      ref={elemRef}
      className={`bg-black px-6 py-10 rounded-md ${
        isVisible && "animate__animated animate__zoomIn"
      }`}
    >
      <div className="flex gap-3">
        {contactTabs.map((tab, idx) => (
          <div
            onClick={() => setActiveTab(idx)}
            key={idx}
            className={`p-4 rounded-lg cursor-pointer text-silver ${
              activeTab === idx ? "bg-mineShaft" : null
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="h-full">{contactTabs[activeTab].component}</div>
    </div>
  );
};

export default ContactUs;
