import { Link } from "react-router-dom";
import { DarkTechwaveLogo, TechwaveLogo } from "../assets/images";
import { motion } from "framer-motion";
import { TWIconTechwave } from "../assets/svgs";

const Logo = ({ inverted, light }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.5 }}
      className="w-fit relative max-[480px]:w-1/2 max-[480px]:h-1/2"
    >
      <Link to="/">
        {inverted ? (
          <div>
            <img
              src={DarkTechwaveLogo}
              alt="where ideas evolve into Innovation"
              className="w-full h-full"
            />
            <p
              className={`absolute ${
                inverted && "text-black"
              } -bottom-5 right-0 text-[14px] flex justify-end`}
            >
              Africa
            </p>
          </div>
        ) : light ? (
          <TWIconTechwave className="w-[245px] max-[480px]:w-[160px] max-[480px]:h-[35px]" />
        ) : (
          <div>
            <img
              src={TechwaveLogo}
              alt="where ideas evolve into Innovation"
              className="w-full h-full"
            />
            <p
              className={`absolute ${
                inverted && "text-black"
              } -bottom-5 right-0 text-[14px] flex justify-end`}
            >
              Africa
            </p>
          </div>
        )}
        {/* <img
          src={
            inverted
              ? DarkTechwaveLogo
              : light
              ? LightTechwaveLogo
              : TechwaveLogo
          }
          alt="where ideas evolve into Innovation"
          className="w-full h-full"
        />
        <p
          className={`absolute ${
            inverted && "text-black"
          } -bottom-5 right-0 text-[14px] flex justify-end`}
        >
          Africa
        </p> */}
      </Link>
    </motion.div>
  );
};

export default Logo;
