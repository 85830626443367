import React, { useEffect, useRef } from "react";
import Hero from "./Hero";
import Services from "./Services";
import Founder from "./Founder";
import Work from "./Work";
import Uniqueness from "./Uniqueness";
import Layout from "../../layout/weblayout";
import useVisible from "../../hooks/useIsVisible";
import Academy from "./Academy";

const HomePageContents = () => {
  const elemRef = useRef();
  const isVisible = useVisible(elemRef);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout light>
      <Hero />
      <Services />
      <Founder />
      <Academy />
      <Work isVisible={isVisible} elemRef={elemRef} />
      <Uniqueness />
    </Layout>
  );
};

export default HomePageContents;
