import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "../../shared/ProgressBar";
import {
  TWIconDiagonalArrow,
  TWIconArrowLeft,
  TWIconArrowRight,
  TWIconGreyArrowLeft,
  TWIconGreyArrowRight,
} from "../../assets/svgs";
import Button from "../../shared/Button";
import { projects } from "../../utils/data";
import { NavLink } from "react-router-dom";

const Work = ({ isVisible, elemRef }) => {
  const [projectIdx, setProjectIdx] = useState(0);
  const timeoutRef = useRef(null);

  const delay = 12000;

  const handlePrevClick = () => {
    if (projectIdx === 0) {
      return;
    }
    setProjectIdx((prev) => prev - 1);
  };

  const handleNextClick = () => {
    if (projectIdx === projects.length - 1) {
      return;
    }
    setProjectIdx((prev) => prev + 1);
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setProjectIdx((prevIndex) =>
          prevIndex === projects.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [projectIdx]);

  return (
    <div
      ref={elemRef}
      className={` ${
        isVisible && "animate__animated animate__zoomIn"
      } overflow-hidden max-w-full mt-0 mx-auto`}
    >
      <div
        className="whitespace-nowrap"
        style={{
          transform: `translate3d(${-projectIdx * 100}%, 0, 0)`,
          transition: "ease 1000ms",
        }}
      >
        {projects.map((project, idx) => (
          <div key={idx} className="inline-block w-full whitespace-normal">
            <div className="flex w-full min-h-[850px]:h-[700px] max-[850px]:flex-wrap max-[850px]:flex-col-reverse">
              <div className="w-2/5 bg-white flex flex-col flex-grow max-[850px]:w-full">
                <div className="h-[700px] max-[880px]:px-6 max-[850px]:h-fit max-[880px]:rounded-lg">
                  <img
                    className="h-full w-full object-fill max-[880px]:rounded-lg"
                    src={project.detailsImage}
                    alt={`More about ${project.title}`}
                  />
                </div>
                <div className="bg-white px-6 pt-4 pb-6 block min-[850px]:hidden">
                  <div className="h-fit">
                    <p className="text-elm text-[20px] max-md:text-[14px] font-bold border-b-2 mt-16 max-[880px]:mt-8 mb-8 w-fit border-elm max-[850px]:text-elm max-[850px]:border-elm">
                      Product
                    </p>
                    <div className="flex my-4 gap-3 items-center">
                      <p className="min-[880px]:ml-auto text-[42px] max-[850px]:text-[32px] max-[850px]:text-black font-bold">
                        {project.logo ? (
                          <img src={project.logo} alt={`${project.title}`} />
                        ) : (
                          <p className="text-[20px]">{project.title}</p>
                        )}
                      </p>
                      {project.image ? (
                        <img
                          className="inline-block max-[880px]:w-[40px] max-[880px]:h-[40px]"
                          src={project.image}
                          alt="project"
                        />
                      ) : project.icon ? (
                        project.icon
                      ) : null}
                    </div>
                    <p className="text-black mb-8">{project.desc}</p>
                    <p className="text-black mb-8 max-[880px]:hidden">
                      {project.desc1 ? project.desc1 : null}
                    </p>
                    <div className="max-[880px]:hidden flex flex-wrap gap-4 justify-between items-center">
                      <Button green>
                        <NavLink
                          className="flex gap-1 items-center"
                          to={project.href}
                        >
                          <p className="text-[14px]">Come take a look here</p>
                          <TWIconDiagonalArrow />
                        </NavLink>
                      </Button>
                      <div className="flex gap-2">
                        <div
                          onClick={handlePrevClick}
                          className={`${
                            projectIdx === 0
                              ? "cursor-default bg-porcelain"
                              : "bg-elm cursor-pointer"
                          }  p-1 rounded-lg`}
                        >
                          {projectIdx === 0 ? (
                            <TWIconGreyArrowLeft className="w-8 h-8" />
                          ) : (
                            <TWIconArrowLeft className="w-8 h-8" />
                          )}
                        </div>
                        <div
                          onClick={handleNextClick}
                          className={`${
                            projectIdx === projects.length - 1
                              ? "cursor-default bg-porcelain"
                              : "bg-elm cursor-pointer"
                          }  p-1 rounded-lg`}
                        >
                          {projectIdx === projects.length - 1 ? (
                            <TWIconGreyArrowRight className="w-8 h-8" />
                          ) : (
                            <TWIconArrowRight className="w-8 h-8" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-3/5 text-black bg-white min-h-full max-[850px]:bg-white px-16 max-[880px]:px-6 py-6 flex justify-between flex-col gap-6 max-[850px]:w-full">
                <div>
                  <ProgressBar progress={project.width} />
                  <p className="text-[18px] max-[880px]:text-[12px] font-medium">
                    Our Work
                  </p>
                  <p className="text-elm text-[20px] max-md:text-[14px] font-bold max-[880px]:hidden border-b-2 mt-16 mb-8 w-fit border-elm max-[850px]:text-elm max-[850px]:border-elm">
                    Product
                  </p>
                  <div className="flex mb-12 gap-3 max-[880px]:hidden items-center">
                    {project.image ? (
                      <img
                        className="inline-block max-[880px]:w-[40px] max-[880px]:h-[40px]"
                        src={project.image}
                        alt="project"
                      />
                    ) : project.icon ? (
                      project.icon
                    ) : null}
                    <p className="text-[42px] max-[850px]:text-[32px] max-[850px]:text-black font-bold">
                      {project.logo ? (
                        <img src={project.logo} alt={`${project.title}`} />
                      ) : (
                        <p className="text-[32px]">{project.title}</p>
                      )}
                    </p>
                  </div>
                  <p className="text-gray block max-[850px]:hidden mb-4">
                    {project.desc}
                  </p>
                  <p className="text-gray block max-[850px]:hidden">
                    {project.desc1 ? project.desc1 : null}
                  </p>
                </div>
                <div className="flex items-center max-[850px]:hidden flex-wrap gap-4 justify-between">
                  <NavLink
                    className="flex gap-1 items-center"
                    to={project.href}
                  >
                    <Button green>
                      <p className="text-[14px]">Come take a look here</p>
                      <TWIconDiagonalArrow />
                    </Button>
                  </NavLink>
                  <div className="flex h-fit gap-3">
                    <div
                      onClick={handlePrevClick}
                      className={`${
                        projectIdx === 0
                          ? "cursor-default bg-porcelain"
                          : "bg-elm cursor-pointer"
                      }  p-1 rounded-lg`}
                    >
                      {projectIdx === 0 ? (
                        <TWIconGreyArrowLeft className="w-8 h-8" />
                      ) : (
                        <TWIconArrowLeft className="w-8 h-8" />
                      )}
                    </div>
                    <div
                      onClick={handleNextClick}
                      className={`${
                        projectIdx === projects.length - 1
                          ? "cursor-default bg-porcelain"
                          : "bg-elm cursor-pointer"
                      }  p-1 rounded-lg`}
                    >
                      {projectIdx === projects.length - 1 ? (
                        <TWIconGreyArrowRight className="w-8 h-8" />
                      ) : (
                        <TWIconArrowRight className="w-8 h-8" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
