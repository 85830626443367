import React, { useRef, useState } from "react";
import { servicesData } from "../../utils/data";
import InputField from "../../shared/InputField";
import { TWIconAttachmentPin } from "../../assets/svgs";
import Button from "../../shared/Button";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import ButtonLoader from "../../shared/ButtonLoader";

const Message = () => {
  const form = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await emailjs
        .sendForm(
          "contact_service",
          "contact_form",
          form.current,
          "OTdU-O6vdb3nS4UFz"
        )
        .then(
          ({ status }) => {
            if (status === 200) {
              toast.success("Request sent!");
              setEmail("");
              setName("");
              setMessage("");
            }
          },
          (error) => {
            toast.error(`Oh no, ${error.text}!`);
          }
        );
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="mb-8">
        <p className="mt-6 text-silver">I'm interested in</p>
        <div className="flex flex-wrap gap-3">
          {[...servicesData, { title: "Others" }].map((service, idx) => (
            <div
              onClick={() => {
                if (idx === 6) {
                  setMessage("I am interested in ");
                  return;
                }
                setMessage(`I am interested in ${service.title}`);
              }}
              className="cursor-pointer px-4 py-4 w-[180px] max-[880px]:w-fit flex justify-center items-center border border-white"
              key={idx}
            >
              <p className="text-center text-[14px]">{service.title}</p>
            </div>
          ))}
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-4">
          <InputField
            placeholder="Your Name"
            required
            name="from_name"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="mb-4">
          <InputField
            placeholder="Your Email"
            required
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="mb-4">
          <InputField
            placeholder="Type Your message"
            name="message"
            type="text"
            required
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <div className="mb-4">
          <label>
            <div className="flex items-center gap-4 cursor-pointer">
              <TWIconAttachmentPin />
              <p className="text-xs">Add Attachment</p>
            </div>
            <input className="hidden" type="file" />
          </label>
        </div>
        <Button green type="submit">
          {isLoading ? <ButtonLoader /> : "Send Request"}
        </Button>
      </form>
    </div>
  );
};

export default Message;
