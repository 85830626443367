import React from "react";
import { AboutTeam } from "../../assets/images";

const Team = () => {
  return (
    <div className="bg-white flex flex-col gap-8 items-center pt-20">
      <p className="text-[64px] font-bold max-[768px]:text-[32px] text-black">
        The Team
      </p>
      <div className="flex items-center h-[500px] max-[600px]:flex-col max-[600px]:h-full">
        <div className="w-1/2 h-[500px] max-[600px]:w-full">
          <img
            className="h-full w-full object-cover"
            src={AboutTeam}
            alt="Amazing team"
          />
        </div>
        <div className="w-1/2 max-[600px]:w-full h-full flex items-center justify-center bg-black text-porcelain px-8 py-24">
          At Techwave, we are more than just a software development company, we
          are dream weavers, weaving the fabric of ideas into tangible
          realities. Our team, an army of brilliant minds, embodies the fusion
          of creativity and technical prowess. With a passion that knows no
          bounds, we embark on a perpetual quest to driving innovations and
          setting the standards in the digital world.
        </div>
      </div>
    </div>
  );
};

export default Team;
