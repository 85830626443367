import React from "react";
import { TWPartyCap } from "../../assets/images";

const Banner = () => {
  return (
    <div className="animate__animated animate__backInUp bg-black mt-28 workBanner px-24 max-[850px]:px-6 py-24 max-[850px]:py-12 flex flex-col justify-center max-[600px]:justify-start">
      <p className="font-clashDisplay text-[64px] max-[600px]:text-[24px] max-md:text-[48px] font-semibold">
        Selected Works:
      </p>
      <div className="my-10">
        <div className="flex items-center">
          <p className="font-clashDisplay text-[96px] max-[600px]:text-[36px] max-md:text-[72px] font-semibold">
            Excellence
          </p>
          <div className="max-[600px]:h-[50px] max-[600px]:w-[50px]">
            <img
              className="h-full w-full"
              src={TWPartyCap}
              alt="Excellence in every byte"
            />
          </div>
        </div>
        <p className="font-clashDisplay text-[96px] max-[600px]:text-[36px] max-md:text-[72px] font-semibold">
          in Every Byte:
        </p>
      </div>
      <p className="font-clashDisplay flex text-[48px] max-md:text-[32px] max-[600px]:text-[24px] font-light">
        Discover Our Remarkable Projects.
      </p>
    </div>
  );
};

export default Banner;
