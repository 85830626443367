import React from "react";
import { motion } from "framer-motion";

const Button = ({ onClick, type, children, state, className, green }) => {
  const defaultBlueClass = green
    ? "h-[60px] min-w-[100px] whitespace-nowrap text-white text-[16px] bg-elm px-4 py-2 flex justify-center items-center"
    : "h-[60px] min-w-[100px] whitespace-nowrap text-white text-[16px] bg-black px-4 py-2 flex justify-center items-center";
  return (
    <motion.button
      whileHover={{ scale: 1.2 }}
      transition={{ duration: 0.5 }}
      onClick={onClick}
      type={type}
      disabled={state}
      className={`${defaultBlueClass} ${className}`}
    >
      {children}
    </motion.button>
  );
};

export default Button;
