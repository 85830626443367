import React, { useEffect, useRef } from "react";
import Banner from "./Banner";
import ContactUs from "./ContactUs";
import Logo from "../../shared/Logo";
import Layout from "../../layout/weblayout";
import useVisible from "../../hooks/useIsVisible";

const ContactContents = () => {
  const elemRef = useRef();
  const isVisible = useVisible(elemRef);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout notShown>
      <div className="p-12 max-[880px]:p-0 max-[880px]:bg-unset">
        <div className="flex max-[880px]:flex-col p-12 max-[880px]:p-0 h-full bg-contact-bg bg-cover bg-no-repeat">
          <div className="max-[880px]:bg-contact-bg bg-no-repeat bg-cover w-1/2 min-h-full max-[880px]:p-6 max-[880px]:w-full">
            <Logo light />
            <Banner />
          </div>
          <div className="w-1/2 min-h-full max-[880px]:w-full">
            <ContactUs isVisible={isVisible} elemRef={elemRef} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactContents;
