import React from "react";

const Banner = () => {
  return (
    <div className="max-[880px]:my-10 flex flex-col justify-center h-full w-4/5">
      <p className="font-clashDisplay max-[880px]:mb-5 mb-12 font-light text-[48px] max-[880px]:text-[16px]">
        Embark on a Journey of Innovation with Us.
      </p>
      <p className="font-clashDisplay font-semibold text-[48px] max-[880px]:text-[16px]">
        Let Your Dreams Take Flight <br /> – Reach Out Today
      </p>
    </div>
  );
};

export default Banner;
