import React from "react";
import Logo from "../shared/Logo";
import { navLinks } from "../utils/data";
import { NavLink } from "react-router-dom";
import { TWIconClose, TWIconGlobe, TWIconMenu } from "../assets/svgs";
import { motion } from "framer-motion";

const Navbar = ({ inverted, setToggle, toggle, notShown, works, light }) => {
  return (
    <div
      className={`fixed z-30 top-0 ${
        inverted ? "bg-white" : "bg-black"
      } w-full pt-8 pb-6 px-12 max-[880px]:px-6 flex items-center gap-4 justify-between ${
        notShown && "hidden"
      }`}
    >
      <Logo inverted={inverted} light={light} />
      <div className="w-2/5 block max-[970px]:hidden">
        <div className="flex justify-between">
          {navLinks.map((link, idx) => (
            <NavLink
              onClick={() => setToggle(false)}
              key={idx}
              to={link.path}
              className={({ isActive }) =>
                `${
                  isActive && ` ${inverted ? "text-green-500" : "text-white"}`
                } ${inverted ? "text-black" : "text-silver"} whitespace-nowrap`
              }
            >
              <motion.p
                whileHover={{ scale: 0.9 }}
                transition={{ duration: 0.5 }}
              >
                {link.title}
              </motion.p>
            </NavLink>
          ))}
        </div>
      </div>
      <div className="flex gap-1 max-[970px]:hidden">
        <TWIconGlobe
          className={`${inverted && "fill-black stroke-black"} w-6 h-6`}
        />
        <select
          className={` ${
            inverted ? "text-black bg-white" : "text-white bg-black"
          } text-[14px]`}
        >
          <option className="text-black">ENG</option>
        </select>
      </div>
      <div className="hidden max-[970px]:block">
        {toggle ? (
          <TWIconClose
            onClick={() => setToggle(false)}
            className={`w-8 h-8 ${inverted && "fill-black stroke-black"}`}
          />
        ) : (
          <TWIconMenu
            onClick={() => setToggle(true)}
            className={`${inverted && "fill-black stroke-black"}`}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
