import {
  IfeDetailsImg,
  IfeDisplayImages,
  IfeoluwaLogo,
  MantledLogo,
  // TWHLDetailsImg,
  // TWHeirloomImg,
  TWMTDetailsImg,
  TWMantledImg,
  TWTKDetailsImg,
  TWTruekeepersImg,
  TruekeepersLogo,
  UnilagDisplayImages,
  UnilagLogo,
} from "../assets/images";
import {
  TWIconMobileLeftIcon,
  TWIconMonitor,
  TWIconMonitorMobile,
  TWIconPenTool,
  TWIconSimCard,
  TWIconStatus,
  TWIconBrush,
  TWIconWebLeftIcon,
  TWIconDesktopLeftIcon,
  TWIconUIUXLeftIcon,
  TWIconProductLeftIcon,
} from "../assets/svgs";

export const navLinks = [
  { title: "Home", path: "/" },
  { title: "Contact us", path: "/contact" },
  { title: "What we worked on", path: "/works" },
  { title: "About", path: "/about" },
];

export const servicesData = [
  {
    leftIcon: <TWIconMobileLeftIcon />,
    rightIcon: <TWIconSimCard />,
    title: "Mobile App Development",
    body: "Elevate your business with our expert mobile app development services. From conceptualization to launch, we craft innovative and user-centric apps that seamlessly blend cutting-edge technology.",
  },
  {
    leftIcon: <TWIconWebLeftIcon />,
    rightIcon: <TWIconMonitorMobile />,
    title: "Web App Development",
    body: "Transform your online presence with our exceptional web application development services.",
  },
  {
    leftIcon: <TWIconUIUXLeftIcon />,
    rightIcon: <TWIconBrush />,
    title: "User Interface Design (UI/UX)",
    body: "Our UI Design expertise brings elegance and functionality together, ensuring seamless interactions that delight users and elevate your product's appeal.",
  },
  {
    leftIcon: <TWIconDesktopLeftIcon />,
    rightIcon: <TWIconMonitor />,
    title: "Desktop App Development",
    body: "Our desktop app development services combines top technology with intuitive design, resulting in powerful applications that enhance efficiency, security, and user experience on the desktop platform.",
  },
  {
    leftIcon: <TWIconMobileLeftIcon />,
    rightIcon: <TWIconStatus />,
    title: "Data Analytics",
    body: "Our data analytics services harness the power of data to uncover valuable patterns, enabling informed decisions that optimize operations, fuel growth, and transform businesses into data-driven success stories",
  },
  {
    leftIcon: <TWIconProductLeftIcon />,
    rightIcon: <TWIconPenTool />,
    title: "Product/Company Branding",
    body: "Our branding services shape compelling narratives and distinctive visuals that resonate with audiences, fostering strong connections and establishing your brand as a true leader in its industry.",
  },
];

export const projects = [
  {
    title: "Mantled",
    logo: MantledLogo,
    desc: "Stay ahead, Track and save valuable assets. Mantled is a comprehensive asset collation and management app with premium solutions. securely store and monitor your valuables in real time. Connect with other assets owners and works with them to mange and safeguard your assets. Mantled will help users store their asset documents and digital valuable for their beneficiaries and future references.",
    desc1:
      "Mantled is your trusted companion for preserving the stories, memories, and treasures that matter most to you. In an ever-evolving digital world, Mantled offers you a secure sanctuary, where your most valuable belongings find their rightful place. Get Mantled Now.",
    href: "http://bemantled.com/",
    detailsImage: TWMTDetailsImg,
    width: "w-[25%]",
  },
  {
    title: "Alumni Association",
    image: UnilagLogo,
    desc: "Introducing the Unilag Alumni App – Your Connection to the Future. We've redesigned our website, launched a powerful mobile app, and equipped our alumni association with an admin dashboard to enhance your engagement and experience. Join us in celebrating our rich history and embracing the digital future!",
    desc1: "",
    href: "/",
    detailsImage: UnilagDisplayImages,
    width: "w-[50%]",
  },
  {
    title: "TrueKeepers",
    image: TruekeepersLogo,
    desc: "Truekeepers is a non-profit organization that supports local NGOs in Nigeria with access to funding, resources and visibility to successfully implement and scale their projects in the communities they operate. We do this by connecting vetted local NGOs with individuals, corporates and donor agencies who are looking to give to charitable projects in a way that drives transparency, accountability and trust for all the stakeholders involved.",
    desc1:
      "At Truekeepers, we believe that by taking donations to the places where they can make the most difference, we can close the needs gap in Africa.",
    href: "https://truekeepers.org",
    detailsImage: TWTKDetailsImg,
    width: "w-[75%]",
  },
  {
    title: "Ifeoluwa Oyedele Foundation",
    image: IfeoluwaLogo,
    desc: "Ifeoluwa Foundation is a nonprofit organization dedicated to improving the lives of underserved communities through various initiatives. For more detailed information, please visit their official website or access their official materials.",
    desc1: "",
    href: "https://ifeoyedelefoundation.org/",
    detailsImage: IfeDetailsImg,
    width: "w-full",
  },
];

export const workProjects = [
  {
    title: "TrueKeepers",
    image: TruekeepersLogo,
    desc: "TrueKeepers is a non-profit organization that supports local NGOs in Nigeria with access to funding, resources and visibility to successfully implement and scale their projects in the communities they operate.",
    href: "https://truekeepers.org",
    displayImages: TWTruekeepersImg,
    background: "bg-TK-bg",
  },
  {
    title: "Mantled",
    logo: MantledLogo,
    desc: "Stay ahead, Track and save valuable assets. Mantled is a comprehensive asset collation and management app with premium solutions. securely store and monitor your valuables in real time.",
    href: "http://bemantled.com/",
    displayImages: TWMantledImg,
    background: "bg-MT-bg",
    reverse: true,
  },
  {
    title: "Ifeoluwa Oyedele Foundation",
    image: IfeoluwaLogo,
    desc: "Ifeoluwa oyedele foundation is a platform that is committed to improving the lives of underserved communities through various sustainable initiatives.",
    href: "https://ifeoyedelefoundation.org/",
    displayImages: IfeDisplayImages,
    background: "bg-Ife-bg",
  },
];

export const homeProjects = [
  {
    title: "TrueKeepers",
    desc: "TrueKeepers empowers Nigerian NGOs with funding and visibility for community project success and growth.",
    href: "http://truekeepers.org",
    width: "w-1/3",
  },
  {
    title: "Mantled",
    desc: "Keep Track Of All Your Valuable Assets in One Place Keep your assets safe and access safer,with premium solutions.",
    href: "http://bemantled.com/",
    width: "w-2/3",
  },
  {
    title: "Ifeoluwa Oyedele Foundation",
    desc: "Improving the lives of underserved communities through various sustainable initiatives.",
    href: "https://ifeoyedelefoundation.org/",
    width: "w-full",
  },
];

export const FAQ = [
  {
    id: 1,
    question: "What is 'Techwave'?",
    answer:
      "Techwave is a platform dedicated to helping entrepreneurs and businesses turn their software business ideas into reality. We provide a range of services and resources to support your journey from concept to a fully functional software product.",
  },
  {
    id: 2,
    question: "How does 'Techwave' work?",
    answer:
      "Techwave works by connecting you with a team of experienced developers, designers, and project managers. We collaborate closely with you to understand your vision, define project requirements, and manage the development process, ensuring your software concept is transformed into a reality.",
  },
  {
    id: 3,
    question: "What types of software projects do you handle?",
    answer:
      "Techwave specializes in a wide range of software projects, from mobile apps and web applications to custom software solutions. Whether you have a specific project in mind or need help shaping your concept, we're here to assist.",
  },
  {
    id: 4,
    question: "How long does the development process typically take?",
    answer:
      "The timeline for each project varies based on its complexity and specific requirements. We work with you to establish a project plan with clear milestones and deadlines, ensuring that your software solution is developed efficiently.",
  },
  {
    id: 5,
    question: "What sets 'Techwave' apart from other development services?",
    answer:
      "At Techwave, we combine technical expertise with a deep understanding of business needs. We prioritize effective communication, a transparent development process, and a commitment to your project's success, ensuring you receive a high-quality, cost-effective solution.",
  },
  {
    id: 6,
    question: "How do I get started with 'Techwave'?",
    answer:
      "To get started, simply reach out to us through our contact page or request a consultation. We'll schedule a meeting to discuss your project idea and determine how we can assist you in bringing it to life.",
  },
  {
    id: 7,
    question: "What is the cost of your services?",
    answer:
      "The cost of our services varies depending on the scope and complexity of your project. We provide customized quotes based on your specific requirements. Our goal is to provide a cost-effective solution that aligns with your budget.",
  },
  {
    id: 8,
    question: "Is my idea safe with 'Techwave'?",
    answer:
      "Yes, your idea is safe with us. We understand the importance of confidentiality and can provide non-disclosure agreements (NDAs) for added protection. Your intellectual property and concepts will be treated with the utmost respect and discretion.",
  },
  {
    id: 9,
    question: "Do you offer ongoing support after the project is completed?",
    answer:
      "Yes, we offer post-launch support and maintenance services to ensure your software continues to perform optimally. We're committed to your long-term success and will be there to address any issues or enhancements as needed..",
  },
  {
    id: 10,
    question: "Can you provide references or case studies of past projects?",
    answer:
      "Absolutely, we can provide references and case studies of previous projects upon request. We believe in transparency and showcasing our track record of successfully delivering software solutions.",
  },
];
