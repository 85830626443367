import React, { useEffect } from "react";
import Banner from "./Banner";
import Projects from "./Projects";
import Layout from "../../layout/weblayout";

const OurWorkContents = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout light works>
      <Banner />
      <Projects />
    </Layout>
  );
};

export default OurWorkContents;
