import React, { useState } from "react";
import { TWIconClose, TWIconMessage } from "../assets/svgs";
import { navLinks } from "../utils/data";
import { Link, NavLink } from "react-router-dom";

const Sidebar = ({ toggle, setToggle }) => {
  const [contactDisplay, setContactDisplay] = useState(true);
  return (
    <div
      className={`z-50 fixed bg-black mt-24 pt-8 w-full px-6 h-screen overflow-hidden ${
        toggle ? "block" : "hidden"
      }`}
    >
      <div className="flex mb-24 flex-col gap-4">
        {navLinks.map((link, idx) => (
          <NavLink
            onClick={() => setToggle(false)}
            key={idx}
            to={link.path}
            className={({ isActive }) =>
              `${
                isActive && "text-white"
              } "text-silver" py-6 border-b text-[16px] border-silver whitespace-nowrap`
            }
          >
            {link.title}
          </NavLink>
        ))}
      </div>
      <div className="flex justify-end">
        <div className="flex items-center gap-2">
          <div className="bg-silverRust w-[50px] h-[50px] flex justify-center items-center rounded-full">
            <TWIconClose onClick={() => setContactDisplay(!contactDisplay)} />
          </div>
          <div className="bg-white px-4 py-2 rounded-2xl">
            <Link to="/contact" className="flex gap-2 items-center">
              <TWIconMessage className="inline" />
              <p
                className={`text-[16px] text-black ${
                  contactDisplay ? "block" : "hidden"
                }`}
              >
                Hi, contact us here
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
