import React, { useState } from "react";
import { FAQ } from "../../utils/data";
import { TWIconArrowDown, TWIconArrowUp } from "../../assets/svgs";

const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="bg-black px-24 max-[850px]:px-6 pb-12">
      <div className="border-t border-boulder">
        {FAQ.map((item, index) => (
          <div
            key={index}
            className={`cursor-pointer py-12 px-24 max-[880px]:px-16 max-md:px-8 max-[880px]:py-6  border-b border-boulder m-2 ${
              index === activeIndex ? "active" : ""
            }`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex justify-between">
              <div className="">
                <div className="text-[24px] max-md:text-[14px]">
                  {item.question}
                </div>
                {index === activeIndex && (
                  <div className=" mt-2 opacity-80 text-[16px] max-md:text-[12px]">
                    {item.answer}
                  </div>
                )}
              </div>
              <div>
                {index === activeIndex ? (
                  <TWIconArrowUp />
                ) : (
                  <TWIconArrowDown />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
