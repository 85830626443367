import React from "react";
import ProjectDiv from "./components/ProjectDiv";
import { workProjects } from "../../utils/data";

const Projects = () => {
  return (
    <div className="px-24 max-[850px]:px-6 pt-24 max-[850px]:pt-12 max-[850px]:pb-4 flex flex-col gap-12">
      {workProjects.map((project, idx) => (
        <ProjectDiv
          key={idx}
          title={project.title}
          logo={project.logo ? project.logo : null}
          link={project.href}
          icon={project.icon ? project.icon : false}
          image={project.image ? project.image : false}
          body={project.desc}
          reverse={project.reverse}
          background={project.background}
          displayImage_1={project.displayImages}
        />
      ))}
    </div>
  );
};

export default Projects;
