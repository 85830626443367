import React from "react";
import Button from "../../shared/Button";
import { TWIconTelephone } from "../../assets/svgs";

const Call = () => {
  return (
    <div className="h-[565px] flex flex-col justify-around items-center">
      <p className="text-[20px] text-center">
        We are available to build with you 24/7, put a call through
      </p>
      <Button green>
        <a
          className="flex justify-center items-center gap-4"
          href="tel:+2348068097620"
        >
          <TWIconTelephone />
          <p>Reach out to us via call</p>
        </a>
      </Button>
    </div>
  );
};

export default Call;
