import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const ProjectDiv = ({
  reverse,
  title,
  icon,
  body,
  link,
  background,
  displayImage_1,
  image,
  logo,
}) => {
  return (
    <div>
      <div className="min-[1230px]:hidden mb-4">
        <p className="underline mb-3">Product</p>
        <div className="flex gap-3 items-center">
          {image ? (
            <img className="inline-block w-8 h-8" src={image} alt="project" />
          ) : icon ? (
            icon
          ) : null}
          <p className="text-[56px] font-bold max-[600px]:text-[32px]">
            {logo ? (
              <img src={logo} alt={`${title}`} />
            ) : (
              <p className="text-[24px] max-md:text-[16px]">{title}</p>
            )}
          </p>
        </div>
      </div>
      <div
        className={`flex max-[880px]:flex-col-reverse items-center gap-24 max-[880px]:gap-6 ${
          reverse && "flex-row-reverse"
        }`}
      >
        <div className="w-1/2 max-[1230px]:w-full">
          <div className="max-[1230px]:hidden">
            <p className="underline mb-4">Product</p>
            <div className="flex gap-3 items-center mb-4">
              {image ? <img src={image} alt="project" /> : icon ? icon : null}
              <p className="text-[48px] font-bold max-[600px]:text-[32px]">
                {logo ? <img src={logo} alt={`${title}`} /> : title}
              </p>
            </div>
          </div>
          <div className="pr-12 max-[880px]:pr-0 flex flex-col gap-6">
            <p className="text-silver mb-4 leading-8">{body}</p>
            <p className="text-gold max-[600px]:text-[14px] pb-2 border-b border-gold w-fit">
              <NavLink target="_blank" to={link}>
                View live product at {link}
              </NavLink>
            </p>
          </div>
        </div>
        <motion.div
          whileHover={{ scale: 0.9 }}
          transition={{ duration: 0.5 }}
          className={`w-1/2 max-[1230px]:w-full h-[300px] flex justify-center ${background} bg-cover rounded-[12px] bg-no-repeat`}
        >
          <div className="flex items-center justify-center gap-4 p-4">
            <img
              src={displayImage_1}
              className="w-full h-full object-contain"
              alt={`${title}-xxx`}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectDiv;
