import React from "react";
import Button from "../../shared/Button";
import { TWFounderImg } from "../../assets/images";
import { Link } from "react-router-dom";

const Founder = () => {
  return (
    <div className="bg-black founderBG max-[610px]:flex-wrap relative flex justify-center items-center">
      <div className="w-1/2 max-[610px]:w-full px-24 max-[850px]:px-6 max-[850px]:py-6 py-16 flex flex-col justify-around mt-6">
        <div>
          <p className="font-clashDisplay text-[36px] max-[740px]:text-[20px] font-medium mb-8 text-center max-[880px]:leading-8">
            "Together, we'll rewrite the digital landscape. Your vision, backed
            by our innovation, will redefine possibilities."
          </p>
          <p className="font-clashDisplay text-center mb-6 text-[15px] font-medium text-silver">
            - Asikhia Iseoluwa, Founder & CE0 Techwave Africa
          </p>
        </div>
        <div className="flex justify-center max-[880px]:hidden">
          <Link to={"/contact"}>
            <Button green>Contact Us</Button>
          </Link>
        </div>
      </div>
      <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8">
        <img
          className="w-full h-full object-cover max-[610px]:rounded-xl"
          src={TWFounderImg}
          alt="Asikhia Iseoluwa"
        />
      </div>
    </div>
  );
};

export default Founder;
