import React from "react";
import {
  GreenEllipse,
  VideoPlaceholder,
  YellowRectangle,
} from "../../assets/images";
import { TWIconVideoCircle } from "../../assets/svgs";

const Video = () => {
  return (
    <div className="w-full relative">
      <img
        className="object-cover"
        src={VideoPlaceholder}
        alt="Our amazing video"
      />
      <img
        className="absolute left-0 bottom-0 max-[880px]:w-[60px]"
        src={GreenEllipse}
        alt="Be innovative"
      />
      <img
        className="absolute right-0 top-0 max-[880px]:w-[60px]"
        src={YellowRectangle}
        alt="Techwave Africa"
      />
      <div className="absolute right-[45%] bottom-[40%]">
        <TWIconVideoCircle className="max-[880px]:w-10 max-[880px]:h-10 cursor-pointer" />
      </div>
    </div>
  );
};

export default Video;
