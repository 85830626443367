const MissionVisionDiv = ({
  text1,
  text2,
  image,
  ellipse,
  rectangle,
  values,
}) => {
  return (
    <div className="flex max-[880px]:flex-col max-[880px]:items-center gap-12">
      <div className="w-3/5 max-[880px]:w-full pr-12 max-[880px]:px-4">
        <div className="opacity-80">
          <p className="text-[24px] max-[880px]:text-[14px] mb-6">{text1}</p>
          <p className="text-[24px] max-[880px]:text-[14px]">{text2}</p>
        </div>
        <div>
          {values?.map(({ header, text }, idx) => (
            <div key={idx} className="mb-4">
              <p className="text-[24px] max-[880px]:text-[14px]">
                <span className="font-semibold text-white">{header}</span>:{" "}
                <span className="opacity-80">{text}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="relative w-2/5 px-3 min-[880px]:pr-12 max-[880px]:w-full">
        <img src={image} alt="Superb vision" className="relative z-10 w-full" />
        <img
          src={rectangle}
          alt="Rectangle"
          className="absolute -top-8 right-0 max-[880px]:w-[150px]"
        />
        <img
          src={ellipse}
          alt="Ellipse"
          className="absolute top-[300px] max-[550px]:top-[150px] right-0 max-[880px]:w-[150px]"
        />
      </div>
    </div>
  );
};

export default MissionVisionDiv;
