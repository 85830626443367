import AboutContents from "../pages/About";
import ContactContents from "../pages/Contact";
import FAQContents from "../pages/FAQ";
import HomePageContents from "../pages/Home";
import NotFound from "../pages/NotFound";
import OurWorkContents from "../pages/Work";

export const routePaths = [
  {
    path: "/",
    element: <HomePageContents />,
  },
  {
    path: "/works",
    element: <OurWorkContents />,
  },
  {
    path: "/contact",
    element: <ContactContents />,
  },
  {
    path: "/about",
    element: <AboutContents />,
  },
  {
    path: "/faq",
    element: <FAQContents />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
