import React, { useState } from "react";
import Logo from "../shared/Logo";
import { NavLink } from "react-router-dom";
import {
  TWIconFacebook,
  TWIconInstagram,
  TWIconLinkedIn,
  TWIconSMS,
  TWIconTelephone,
  TWIconTwitter,
} from "../assets/svgs";
import { toast } from "react-toastify";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.success(`Thank you for subscribing to our newsletter!`);
    setEmail("");
  };

  return (
    <div className="px-12 py-6 border-t border-[#181818] mb-16 max-[850px]:px-6 bg-black mt-12">
      <div className="py-6 flex gap-6 justify-between flex-wrap border-b border-boulder">
        <Logo light />
        <form onSubmit={handleSubmit} className="max-md:w-full">
          <p className="text-[20px] max-sm:text-[16px]">
            Subscribe to our newsletter
          </p>
          <div className="flex w-[400px] max-md:w-full h-[50px] max-[560px]:w-full border border-white">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              type="email"
              className="pl-4 w-full bg-black  placeholder:font-clashDisplay placeholder:text-[14px] placeholder:text-[#9F9C9C] outline-none"
              placeholder="Your email address"
            />
            <button type="submit">
              <div className="h-[47px] bg-elm flex justify-center items-center px-8 py-4 max-sm:px-4 max-sm:py-2">
                <p className="text-[14px] max-sm:text-[12px]">{"Subscribe"}</p>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div className="servicesCard mt-8 gap-24">
        <div className="flex flex-col gap-4">
          <p className="text-[16px] text-silver pb-3">Pages</p>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/works">What we worked on</NavLink>
          <NavLink to="/contact">Contact Us</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/faq">FAQ</NavLink>
        </div>
        <div className="flex flex-col gap-8">
          <p className="text-[16px] text-silver pb-3">Address</p>
          <p>Gbagada, Lagos Nigeria.</p>
          <div>
            <div className="flex gap-2 mb-2">
              <TWIconTelephone className="w-5 h-5" />
              <p className="underline text-[14px]">+234 8068097620</p>
            </div>
            <div className="flex gap-2">
              <TWIconSMS className="w-5 h-5" />
              <p className="text-[14px]">contactus@techwaveteam.com </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <p className="text-[16px] text-silver pb-3">Social Links</p>
          <div className="flex items-center gap-8">
            <NavLink to="/">
              <TWIconFacebook />
            </NavLink>
            <NavLink to="/services">
              <TWIconTwitter />
            </NavLink>
            <NavLink to="/contact">
              <TWIconLinkedIn />
            </NavLink>
            <NavLink to="/about">
              <TWIconInstagram />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
