import { Link } from "react-router-dom";
import {
  Ellipse28,
  Ellipse29,
  Ellipse30,
  Ellipse31,
  Ellipse32,
  Ellipse33,
  Ellipse34,
  TechwaveAcademylogo,
} from "../../assets/images";
import { TWIconYellowPointedArrowRight } from "../../assets/svgs";

const Academy = () => {
  return (
    <div className="bg-academy-bg bg-cover bg-no-repeat text-silver px-24 py-16 max-[880px]:px-6 flex flex-col gap-6">
      <div className="flex justify-center mb-4">
        <img
          className="max-[880px]:w-[100px]"
          src={TechwaveAcademylogo}
          alt="we create both exceptional innovative products"
        />
      </div>
      <div className="bg-academyDotted-bg bg-center bg-contain bg-no-repeat flex flex-col gap-16 items-center">
        <p className="w-4/5 max-[880px]:w-full text-center text-[24px] max-[880px]:text-[16px]">
          Techwave is envisioned to empower thousands of talents around the
          world with technical skills in the next 10 years. At Techwave, we want
          to equip aspiring tech professionals with the digital skills in
          engineering, design and data which is necessary to excel in the
          dynamic world of technology.
        </p>
        <div className="flex flex-col items-center gap-12">
          <div className="grid grid-cols-4 gap-24 max-[880px]:gap-12">
            <img src={Ellipse28} alt="future talents" />
            <img src={Ellipse29} alt="future talents" />
            <img src={Ellipse30} alt="future talents" />
            <img src={Ellipse31} alt="future talents" />
          </div>
          <div className="grid grid-cols-3 gap-24 max-[880px]:gap-12">
            <img src={Ellipse32} alt="future talents" />
            <img src={Ellipse33} alt="future talents" />
            <img src={Ellipse34} alt="future talents" />
          </div>
        </div>
        <Link to={"https://academy.techwaveafrica.com/"}>
          <div className=" flex items-center gap-1">
            <p className="text-gold text-[24px] max-[880px]:text-[14px] pb-1 border-b border-gold">
              Check Out Techwave Academy
            </p>
            <div className="animate__animated animate__shakeX animate__infinite animate__slow">
              <TWIconYellowPointedArrowRight className="w-8 h-8" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Academy;
