import React, { useEffect, useState } from "react";
import MissionVisionDiv from "./components/MissionVisionDiv";
import {
  CoreValuesEllipse,
  CoreValuesImage,
  CoreValuesRectangle,
  MissionEllipse,
  MissionImage,
  MissionRectangle,
  VisionEllipse,
  VisionImage,
  VisionRectangle,
} from "../../assets/images";

const MissionVision = () => {
  const getRoute = localStorage.getItem("route");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getRoute === "0"
      ? setActiveTab(0)
      : getRoute === "1"
      ? setActiveTab(1)
      : getRoute === "2"
      ? setActiveTab(2)
      : setActiveTab(0);
  }, [getRoute]);

  const missionTabs = [
    {
      title: "Vision",
      component: (
        <MissionVisionDiv
          text1="Techwave is envisioned to empower Africa's future through innovation and talent development. We aim to propel Africa into a new era of technological prominence, where innovation flourishes and talents thrive."
          text2="We envision a continent transformed, led by cutting-edge solutions and empowered individuals. Through unwavering dedication, TechWave aspires to redefine the possibilities of technology, fostering a future where Africa's influence in the global tech landscape is both profound and enduring."
          image={VisionImage}
          ellipse={VisionEllipse}
          rectangle={VisionRectangle}
        />
      ),
    },
    {
      title: "Mission",
      component: (
        <MissionVisionDiv
          text1="At Techwave, our mission is to harness the boundless potential of technology to empower businesses, elevate experiences, and shape a more connected world. Rooted in innovation, driven by expertise, and fueled by passion, we are committed to delivering software solutions that not only meet the needs of today but anticipate the demands of tomorrow."
          image={MissionImage}
          ellipse={MissionEllipse}
          rectangle={MissionRectangle}
        />
      ),
    },
    {
      title: "Our Core Values",
      component: (
        <MissionVisionDiv
          text1="At TechWave, our core values form the bedrock of our identity, guiding every interaction, decision, and innovation."
          values={[
            {
              header: "Communication",
              text: "We believe in the power of transparent and open communication. Through active listening and clear expression, we foster understanding, trust, and meaningful connections within our team and with our clients.",
            },
            {
              header: "Godliness",
              text: "Integrity, honesty, and ethical conduct are the cornerstones of our character. We uphold moral principles in all our endeavors, ensuring that our actions reflect the highest standards of Godliness and righteousness.",
            },
            {
              header: "Teamwork",
              text: "We celebrate the strength of collaboration and synergy. Recognizing that we achieve more collectively than individually, we work cohesively, valuing diverse perspectives and leveraging the unique strengths of each team member.",
            },
            {
              header: "Creative Thinking",
              text: "Innovation is our heartbeat. We nurture an environment where imaginative ideas flourish. By encouraging bold thinking and embracing change, we continuously push boundaries, pioneering inventive solutions that transform challenges into opportunities.",
            },
            {
              header: "Personal Growth",
              text: "We are committed to the growth and development of every individual within our TechWave family. Through mentorship, learning opportunities, and support, we empower our team members to realize their full potential, both personally and professionally.",
            },
            {
              header: "Accountability",
              text: "We stand accountable for our actions and decisions. Taking ownership of our responsibilities, we deliver on our promises with diligence and excellence. We learn from setbacks, celebrate achievements, and continuously strive for improvement.",
            },
          ]}
          image={CoreValuesImage}
          ellipse={CoreValuesEllipse}
          rectangle={CoreValuesRectangle}
        />
      ),
    },
  ];

  return (
    <div className="bg-black min-h-[calc(100vh-117px)] pl-12 pt-12 max-[768px]:pl-4 max-[880px]:py-12">
      <div className="flex gap-3">
        {missionTabs.map((tab, idx) => (
          <div
            onClick={() => setActiveTab(idx)}
            key={idx}
            className={`p-4 rounded-lg whitespace-nowrap cursor-pointer text-silver ${
              activeTab === idx ? "bg-mineShaft" : null
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="pt-20 -z-10">{missionTabs[activeTab].component}</div>
    </div>
  );
};

export default MissionVision;
