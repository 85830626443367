import React from "react";

const Banner = () => {
  return (
    <div className="text-black py-36 max-[880px]:py-24 bg-white mt-20">
      <div className="animate__animated animate__backInDown w-3/4 max-[880px]:w-full flex flex-col justify-around h-full px-12 max-[880px]:px-6">
        <p className="font-clashDisplay text-[48px] font-bold max-[800px]:text-[32px] max-[500px]:text-[24px] mb-10">
          About Techwave Africa
        </p>
        <p className="text-[24px] font-light max-[500px]:text-[16px]">
          Techwave Africa is a faith-based tech startup committed with the
          vision of building technological innovations, providing tech
          consultancy to businesses, and fostering the growth of tech talents
          through a dedicated academy. In every line of code we write and every
          solution we provide, there is a promise - a promise to transform, to
          simplify, and to illuminate the path forward. We understand that
          technology is not just a tool; it's a bridge that connects aspirations
          to achievements, ideas to innovations, and businesses to their
          brightest futures.
        </p>
      </div>
    </div>
  );
};

export default Banner;
