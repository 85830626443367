import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

const Layout = ({ children, inverted, notShown, works, light }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <Navbar
        notShown={notShown}
        inverted={inverted}
        toggle={toggle}
        setToggle={setToggle}
        works={works}
        light={light}
      />
      <Sidebar toggle={toggle} setToggle={setToggle} />
      <div
        className={` ${
          toggle ? "overflow-hidden h-[500px]" : "flex flex-col flex-1 h-full"
        }`}
      >
        {children}
      </div>
      {!toggle ? <Footer /> : null}
    </div>
  );
};

export default Layout;
